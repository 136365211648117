.number-circle {
  display: flex;
  background-color: #FFD600;
  border-radius: 50px;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1/1;
  width: 50px;
  height: 50px;
}

.number-circle > p {
  margin: 0;
  padding: 0;
  font-weight: 800;
  font-size: 20px;
  line-height: 50px;
  color: #FFF;
}

@media only screen and (max-width: 991px) and (min-width: 500px){
  .number-circle {
    display: flex;
    background-color: #FFD600;
    border-radius: 50px;
    justify-content: center;
    align-items: center;
    aspect-ratio: 1/1;
    width: 60px;
    height: 60px;
  }
  
  .number-circle > p {
    margin: 0;
    padding: 0;
    font-weight: 800;
    font-size: 30px;
    line-height: 30px;
    color: #FFF;
  }

}

@media only screen and (min-width: 991px){
  .number-circle {
    display: flex;
    background-color: #FFD600;
    border-radius: 50px;
    justify-content: center;
    align-items: center;
    aspect-ratio: 1/1;
    width: 70px;
    height: 70px;
  }
  
  .number-circle > p {
    margin: 0;
    padding: 0;
    font-weight: 800;
    font-size: 40px;
    line-height: 70px;
    color: #FFF;
  }
}