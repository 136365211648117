.button-container {
  background-color: #000000;
  width: 168px;
  height: 60px;
}

button, input[type="submit"], input[type="reset"] {
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

.button-container > p {
  padding: 0;
  margin: 0;
  color: #FFF;
  font-size: 15px;
  line-height: 30px;
  font-weight: 800;
}

.button-container > a > p {
  padding: 0;
  margin: 0;
  color: #FFF;
  font-size: 15px;
  line-height: 30px;
  font-weight: 800;
}