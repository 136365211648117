p {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  white-space: pre-wrap;

}

a {
  text-decoration: none;
  color: #0500FF;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
}

.main-title {
  color: #FFD600;
  font-size: 60px;
  font-weight: 800;
  line-height: 60px;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
}

.title-primary {
  color: #000000;
  font-size: 50px;
  font-weight: 800;
  line-height: 60px;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
}

.title-secondary {
  color: #000000;
  font-size: 30px;
  font-weight: 800;
  line-height: 40px;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
}

.title-ternary {
  color: #000000;
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
}

.text {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-size: 16px;
  line-height: 32px;
  font-weight: 500;
  color: #1A1A1A;
  white-space: pre-wrap;
}

h3 {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-size: 16px;
  line-height: 38px;
  color: #FFF;
}

h4 {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-size: 14px;
  line-height: 28px;
  color: #FFF;
}

@media only screen and (max-width: 991px) and (min-width: 576px) {
  p {
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
  }
  
  a {
    text-decoration: none;
    color: #0500FF;
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
  }
  
  .main-title {
    color: #FFD600;
    font-size: 80px;
    font-weight: 800;
    line-height: 80px;
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
  }
  
  .title-secondary {
    color: #000000;
    font-size: 50px;
    font-weight: 800;
    line-height: 60px;
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
  }
  
  .title-ternary {
    color: #000000;
    font-size: 30px;
    font-weight: 700;
    line-height: 40px;
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
  }
  
  .text {
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-size: 18px;
    line-height: 34px;
    font-weight: 500;
    color: #1A1A1A;
  }
  
  h3 {
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    color: #FFF;
  }
  
  h4 {
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-size: 14px;
    line-height: 26px;
    color: #FFF;
  }
}

@media only screen and (min-width: 991px) {
  p {
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
  }
  
  a {
    text-decoration: none;
    color: #0500FF;
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
  }
  
  .main-title {
    color: #FFD600;
    font-size: 100px;
    font-weight: 800;
    line-height: 100px;
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
  }
  
  .title-secondary {
    color: #000000;
    font-size: 70px;
    font-weight: 800;
    line-height: 80px;
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
  }
  
  .title-ternary {
    color: #000000;
    font-size: 50px;
    font-weight: 700;
    line-height: 60px;
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
  }
  
  .text {
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-size: 20px;
    line-height: 40px;
    font-weight: 500;
    color: #1A1A1A;
  }
  
  h3 {
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-size: 18px;
    line-height: 30px;
    color: #FFF;
  }
  
  h4 {
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    color: #FFF;
  }
}
