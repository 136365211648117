@media only screen and (max-width: 576px) {
  .hero-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-top: 47px !important;
    margin-bottom: 100px;
  }
  
  .home-hero-section-container {
    flex-direction: column-reverse !important;
    margin-top: -50px !important;
    margin-bottom: 110px !important;
  }
  
  .text-container {
    flex: 0.5;
  }
  
  .text-container > h1 {
    margin-bottom: 50px !important;
    font-size: 80px;
    line-height: 70px;
  }
  
  .image-container> div > img, .image-container > div {
    width: 100%;
  }
  .image-container {
    flex: 0.5;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 80px;
  }
  
  .text-container > :not(:first-child) {
    margin-bottom: 20px !important;
  }
  
  .flex-8 {
    flex: 0.8 !important;
  }
}

@media only screen and (max-width: 700px) and (min-width: 576px) {
  .hero-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-top: 107px;
    margin-bottom: 200px;
  }
  
  .text-container {
    flex: 0.5;
  }
  
  .text-container > h1 {
    margin-bottom: 70px;
  }
  
  .image-container {
    display: flex;
    justify-content: center;
    margin-top: 100px;
  }
  
  .text-container > :not(:first-child) {
    margin-bottom: 40px;
  }
  
  .flex-8 {
    flex: 0.8 !important;
  }
}

@media only screen and (max-width: 992px) and (min-width: 700px) {
  .hero-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-top: 107px;
    margin-bottom: 200px;
  }
  
  .text-container {
    flex: 0.5;
  }
  
  .text-container > h1 {
    margin-bottom: 70px;
  }
  
  .image-container {
    display: flex;
    justify-content: center;
    margin-top: 100px;
  }
  
  .text-container > :not(:first-child) {
    margin-bottom: 40px;
  }
  
  .flex-8 {
    flex: 0.8 !important;
  }
}


@media only screen and (min-width: 992px) {
  .hero-container {
    display: flex;
    flex-direction: row;
    flex: 1;
    margin-top: 107px;
    margin-bottom: 200px;
  }
  
  .text-container {
    flex: 0.5;
  }
  
  .text-container > h1 {
    margin-bottom: 70px;
  }
  
  .image-container {
    flex: 0.5;
    display: flex;
    justify-content: flex-end;
  }
  
  .text-container > :not(:first-child) {
    margin-bottom: 40px;
  }
  
  .flex-8 {
    flex: 0.8 !important;
  }
  .image-container > div {
    height: 400px;
  }
}