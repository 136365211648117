/* .view-another-title {
  color: rgba(255, 214, 0, 1);
}

.carousel-container {
  width: 100%;
  padding-top: 200px; 
}

.carousel-track {
  display: flex;
  flex:1;
  width: 100vw;
  overflow: hidden;
  padding: 0 280px
}

.carousel-item {
  display: flex;
  margin: 0 30px;
  transition: .3s all ease;
}

.carousel-item > .project-card-container > .project-card-image-container > img {
  max-height: 500px;
  max-width: 500px;

}
.carousel-item > .project-card-container {
  width: auto;
  height: auto;
  max-height: 1000px;
}

.carousel-item {
  margin-right: 360px;
}

.carousel-controls {
  position: relative;
  z-index: 999;
  display: flex;
  top: 300px;
  justify-content: space-between;
}

.carousel-controls > * {
  background-color: yellow;
}


@media only screen and (min-width: 1000px) {
  .carousel-item > .project-card-container {
    width: 60vw;
    height: auto;
  }
}
 */


.view-another-title {
  color: rgba(255, 214, 0, 1);
}

.carousel-container {
  width: 100%;
  padding-top: 200px; 
  padding-bottom: 200px; 
  overflow: hidden;
}

.carousel-track {
  display: flex;
  flex:1;
  width: 100vw;
  overflow: hidden;
  padding: 0 11%;
}

.carousel-item {
  display: flex;
  /* margin: 0 30px; */
  transition: .3s all ease;
}

.carousel-item > .project-card-container > .project-card-image-container > img {
  max-height: 500px;
  max-width: 500px;

}
.carousel-item > .project-card-container {
  width: auto;
  height: auto;
  max-height: 1000px;
}

.carousel-item {
  /* margin-right: 360px; */
}

.carousel-controls {
  position: relative;
  z-index: 999;
  display: flex;
  top: 300px;
  justify-content: space-between;
  margin: 0 20px;
}

.carousel-controls > div > svg {
  background-color: rgba(114, 113, 113, 0.1);
  width: 30px;
  height: 30px;
  border-radius: 10px;
}


@media only screen and (max-width: 560px) {
  .carousel-container {
    width: 100%;
    padding-top: 100px !important; 
    padding-bottom: 100px !important; 
    overflow: hidden;
  }

  .view-another-title {
    padding-left: 18px;
  }
}

@media only screen and (min-width: 560px) and (max-width: 960px) {
  .carousel-track {
    padding-left: 15% !important;
  }
    
  .carousel-controls > div > svg {
    background-color: rgba(114, 113, 113, 0.1);
    width: 40px;
    height: 40px;
    border-radius: 10px;
  }

  .carousel-controls {
    margin: 0 40px;
  }
}

@media only screen and (min-width: 1000px) {
  .carousel-item > .project-card-container {
    width: 60vw;
    height: auto;
  }
  
  .carousel-controls > div > svg {
    background-color: rgba(114, 113, 113, 0.1);
    width: 60px;
    height: 60px;
    border-radius: 10px;
  }

  .carousel-controls {
    margin: 0 40px;
  }
  
}

@media only screen and (min-width: 1500px) and (max-width: 1900px) {
  .carousel-controls > div > svg {
    background-color: rgba(114, 113, 113, 0.1);
    width: 100px;
    height: 100px;
    border-radius: 10px;
  }
  
}