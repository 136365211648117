.contact-container {
  display: flex;
  flex:1;
  flex-direction: column-reverse;
  justify-content: space-between;
  margin-bottom: 203px;
}

@media only screen and (min-width: 991px) {
  .contact-container {
    display: flex;
    flex:1;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 203px;
  }
}