
@media only screen and (max-width: 576px){
  .facts-container > .container {
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  
  .facts-container {
    background-color: #0500FF;
    padding: 100px 18px !important;
    margin-bottom: 100px;
    display: flex;
    flex:1;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .facts-container-title {
    color: #fFF !important;
    margin-bottom: 70px;
    font-size: 28px !important;
    font-weight: '700' !important;
    line-height: 50px !important;
    margin-left: -30px !important;
  }
}

@media only screen and (min-width:576px) and  (max-width: 960px){
  .facts-container > .container {
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  .facts-container {
    background-color: #0500FF;
    padding: 100px 0 !important;
    margin-bottom: 100px;
    display: flex;
    flex:1;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .facts-container-title {
    color: #fFF !important;
    margin-bottom: 70px;
    font-size: 40px !important;
    font-weight: '700' !important;
    line-height: 50px !important;
  }
}

@media only screen and (min-width: 960px) and (max-width: 1300px) {
  .facts-container > .container {
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  .facts-container {
    background-color: #0500FF;
    padding: 100px 0 !important;
    margin-bottom: 100px;
    display: flex;
    flex:1;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .facts-container-title {
    color: #fFF !important;
    margin-bottom: 70px;
    font-size: 50px !important;
    font-weight: '700' !important;
    line-height: 60px !important;
  }
}


@media only screen and (min-width: 1300px) {
  .facts-container {
    background-color: #0500FF;
    padding: 100px 250px !important;
    display: flex;
    margin-bottom: 0;
    flex:1;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .facts-container-title {
    color: #fFF !important;
    margin-bottom: 70px;
    font-size: 50px !important;
    font-weight: '700' !important;
    line-height: 60px !important;
  }
}


@media only screen and (min-width: 1700px) {
  .facts-container {
    margin-bottom: 0;
    background-color: #0500FF;
    padding: 100px 400px !important;
    display: flex;
    flex:1;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .facts-container-title {
    color: #fFF !important;
    margin-bottom: 70px;
    font-size: 50px !important;
    font-weight: '700' !important;
    line-height: 60px !important;
  }
}