.al-logo-container {
  width: 40px !important;
  object-fit: contain;
}

.al-logo-container > a {
  width: 100%;
  height: 100%;
}

.al-logo-container > a > img {
  width: 100%;
  height: 100%;
  object-fit: contain !important;
}

.navbar-container {
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

}

.links-container {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.links-container > .link {
  margin: 0 20px
}

.active > .link-underline {
  width: 100% !important;
  height: 2px !important;
}

.active > p {
  font-weight: 700;
}

.link-underline {
  background-color: #0500FF;
  margin-top: -15px;
  transition: .2s all;
  width: 0px;
  height: 2px;
}

.navbar-sticky-container {
  position: absolute;
  z-index: 999999;
  width: 100%;
  left: 0;
  top: -100px;
  
  /* Glass effect */
  background: rgba(255, 255, 255, 1);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  transition: .7s all ease-in-out;
}

.navbar-sticky-content-container {
  display: flex;
  flex-direction: row;
  padding-top: 10px;
  padding-bottom: 10px;
}

.navbar-sticky-active {
  top: 0;
  position: fixed;
  transition: .7s all ease-in-out;

}

.navbar-sticky-inactive {
  top: -100px !important;
  position: fixed;
  transition: .7s all ease-in-out;
}

.navbar-open-btn {
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
}



@media only screen and (max-width: 700px) and (min-width: 576px) {
  .hero-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-top: 107px;
    margin-bottom: 200px;
  }
  
  .text-container {
    flex: 0.5;
  }
  
  .text-container > h1 {
    margin-bottom: 70px;
  }
  
  .image-container {
    display: flex;
    justify-content: center;
    margin-top: 100px;
  }
  
  .text-container > :not(:first-child) {
    margin-bottom: 40px;
  }
  
  .flex-8 {
    flex: 0.8 !important;
  }
}

@media only screen and (max-width: 991px) and (min-width: 768px) {
  .al-logo-container {
    width: 54px !important;
    object-fit: contain;
  }
}
@media only screen and (max-width: 768px) {
  
  .text-container {
    flex: 0.5;
  }
  
  .text-container > h1 {
    margin-bottom: 70px;
  }
  
  .image-container {
    display: flex;
    justify-content: center;
    margin-top: 100px;
  }
  
  .text-container > :not(:first-child) {
    margin-bottom: 40px;
  }
  
  .flex-8 {
    flex: 0.8 !important;
  }
}


@media only screen and (min-width: 992px) {
 .al-logo-container {
    width: 74px !important;
    object-fit: contain;
  }
  
  .navbar-container {
    padding-top: 70px;
    padding-bottom: 70px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  
  }
  
  .links-container {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  
  .links-container > .link {
    margin: 0 20px
  }
  
  .active > .link-underline {
    width: 100% !important;
    height: 2px !important;
  }
  
  .active > p {
    font-weight: 700;
  }
  
  .link-underline {
    background-color: #0500FF;
    margin-top: -15px;
    transition: .2s all;
    width: 0px;
    height: 2px;
  }
  
  .navbar-sticky-container {
    position: absolute;
    z-index: 999999;
    width: 100%;
    left: 0;
    top: -100px;
    
    /* Glass effect */
    /* background: rgba(255, 214, 0, 0.8); */
    background: rgba(255, 255, 255, 1);

    /* border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px; */
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    transition: .7s all ease-in-out;
  }
  
  .navbar-sticky-content-container {
    display: flex;
    flex-direction: row;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  
  .navbar-sticky-active {
    top: 0;
    position: fixed;
    transition: .7s all ease-in-out;
  
  }
  
  .navbar-sticky-inactive {
    top: -100px !important;
    position: fixed;
    transition: .7s all ease-in-out;
  }
}


/* DRAWER */

.navbar-drawer-container {
  background-color: #FFF;
  position: fixed;
  top:0;
  width: 100%;
  height: 100%;
  z-index: 99999999;
  transition: .3s left ease-in-out;
}

.navbar-drawer-visible {
  left: 0 !important;
}
.navbar-drawer-hidden {
  left: 100vw !important;
}

.navbar-drawer-close-icon-container {
  top: 30px;
  right: 30px;
  position: absolute;
}

.navbar-drawer-items-container {
  margin-top: 200px; 
  display: flex; 
  flex-direction: column;
  align-items: start;
  padding: 0 50px;
}

.navbar-drawer-items-container > * {
  margin-bottom: 30px;
  color: black;
}

.divider {
  background-color: #ECECEC;
  height: 1px !important;
  width: 100% !important;;
}

.navbar-drawer-items-container > .link > p {
  margin-bottom: 0;
}

.navbar-drawer-items-container > .link > p > a {
  color: black !important;  
}

.navbar-drawer-items-container > .link {
  width: 100%;
}

.navbar-drawer-items-container > .link > p > a {
  width: 100%;
  display: flex;
  flex:1;
}