.what-i-learned-container {
  padding: 80px 60px;
}

.what-i-learned-content-container {
  background-color: #FFD600;
  padding: 80px 50px;
}

.what-i-learned-text-container {
  margin-top: 30px; 
}

.what-i-learned-text-container > p:not(:last-child) {
  margin-bottom: 20px;
}

@media only screen and (max-width: 576px) {
  .what-i-learned-container {
    padding: 0px;
  }

  .what-i-learned-content-container {
    padding-left: 30px;  
    padding-right: 30px;  
  }
}

@media only screen and (min-width: 991px) {
  .what-i-learned-container {
    padding: 200px 0;
  }
  
  .what-i-learned-content-container {
    background-color: #FFD600;
    padding: 100px 85px;
  }
  
  .what-i-learned-text-container {
    margin-top: 70px; 
  }
  
  .what-i-learned-text-container > p:not(:last-child) {
    margin-bottom: 70px;
  }
}