.project-url-container {
  background-color: #0500FF ;
  padding: 80px 40px; 
}

.project-url-url-title {
  display: inline-block;
  color: #FFF;
  /* background-color: red; */
}

.project-url-content-container > div > h4 > a {
  color: #FFF;
}

.project-url-content-container > p {
  color: #FFF;
}

.project-url-line {
  background-color: #FFF;
  height: 5px;
}

@media only screen and (min-width: 576px) {
  .project-url-container {
    background-color: #0500FF ;
    padding: 100px 0; 
  }
  
  .project-url-url-title {
    display: inline-block;
    color: #FFF;
    /* background-color: red; */
  }
  
  .project-url-content-container > div > h4 > a {
    color: #FFF;
  }
  
  .project-url-content-container > p {
    color: #FFF;
  }
  
  .project-url-line {
    background-color: #FFF;
    height: 5px;
  }
  
}