.contact-info-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* margin-top: -100px; */
  margin-bottom: 100px;
  overflow: hidden;
}

.contact-info-image-container {
  max-width:  400px;
  /* height: 200px !important; */
  /* aspect-ratio: 1/1 !important; */
}

.contact-info-text-container {
  background-color: #0500FF;
  width: 400px;
  padding: 30px;
}

.contact-info-text-container > h3 {
  margin-bottom: -5px;
}

.contact-info-text-container > h4 {
 margin-bottom: 38px;
}

.social-container {
  display: flex;
  flex-direction: column;
}

.contact-info-image-container {
  width: 100% !important;
}

.contact-info-image {
  width: 100% !important;
}
@media only screen and (max-width:375px) {
  .contact-info-text-container {
    padding: 50px !important;
  }
}

@media only screen and (max-width:335px) {
  .contact-info-text-container {
    padding: 70px !important;
  }
}


@media only screen and (min-width:991px) {

  .contact-info-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0;
  }
  
  .contact-info-text-container {
    background-color: #0500FF;
    width: 400px;
    padding: 30px;
  }
  
  .contact-info-text-container > h3 {
    margin-bottom: -5px;
  }
  
  .contact-info-text-container > h4 {
   margin-bottom: 38px;
  }
  
  .social-container {
    display: flex;
    flex-direction: column;
  }
}