.about-project-container {
  background-color: #FFD600;
  padding-top: 80px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 120px;
}

.about-project-text-container {
 margin-top: 30px; 
 width: 100%;
 display: flex;
 flex-direction: column;
 flex: 1;
}

.about-project-text-item {
  flex:1;
  display: flex;
  flex-direction: column;
}
.about-project-text-item:not(:first-child) {
  margin-top: 20px
}

@media only screen and (min-width: 991px){
  .about-project-container {
    background-color: #FFD600;
    padding-top: 150px;
    padding-left: 100px;
    padding-right: 100px;
    padding-bottom: 300px;
  }
  
  .about-project-text-container {
   margin-top: 70px; 
   width: 80%;
  }
  
  .about-project-text-item:not(:first-child) {
    margin-top: 40px
  }
}