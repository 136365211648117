.project-about-container {
  display: flex;
  flex:1;
  flex-direction: column;
  align-items: center;
  margin-top: 80px;
}

@media only screen and (min-width:991px) {
  .project-about-container {
    display: flex;
    flex:1;
    flex-direction: column;
    align-items: center;
    margin-top: 100px;
    margin-bottom: 100px;
  }
  
}