.my-role-container {
  background-color: #0500FF;
  padding-top: 80px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 80px;
  width: 100%;
  margin-top: -70px;
}

.my-role-title {
  color: #FFF;
}

.my-role-text-container {
  margin-top: 30px; 
  width: 80%;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.my-role-text-item:not(:first-child) {
  margin-top: 20px
}

.my-role-text-container > p {
  color: #FFF;
  text-align: left;
  text-justify: inter-character;
  word-break: break-word;  
  hyphens: auto;
  display: flex;
  flex-direction: column;

}

@media only screen and (min-width: 991px){
  .my-role-container {
    background-color: #0500FF;
    padding-top: 150px;
    padding-left: 100px;
    padding-right: 100px;
    padding-bottom: 150px;
    width: 80%;
    margin-top: -200px;
  }
  
  .my-role-title {
    color: #FFF;
  }
  
  .my-role-text-container {
    margin-top: 70px; 
    width: 80%;
  }
  
  .my-role-text-item:not(:first-child) {
    margin-top: 40px
  }
  
  .my-role-text-container > p {
    color: #FFF;
  }
  
}