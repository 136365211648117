.get-in-touch-container {
  margin-top: 0px;
  margin-bottom: 120px;
  padding: 0 40px;
}

.get-in-touch-container > h1 {
  display: flex;
}

.get-in-touch-container > h1 > a {
  margin-top: 0px;
  font-size: 80px;
  line-height: 70px;
  font-weight: 800;
  color: #FFF;
  text-shadow: 1px 0 #000, -1px 0 #000, 0 1px #000, 0 -1px #000,
  1px 1px #000, -1px -1px #000, 1px -1px #000, -1px 1px #000;
  transition: 0.2s all ease-in-out;
  text-align: center;
}

.get-in-touch-container > p {
  text-align: center;
}


@media only screen and (max-width: 768px)  and (min-width: 500px)  {
  .get-in-touch-container > h1 > a {
    font-size: 60px;
    line-height: 100px;
    font-weight: 800;
    color: #FFF;
    text-shadow: 2px 0 #000, -2px 0 #000, 0 2px #000, 0 -2px #000,
    1px 1px #000, -1px -1px #000, 1px -1px #000, -1px 1px #000;
    transition: 0.2s all ease-in-out;
  }
}

@media only screen and (max-width: 991px)  and (min-width: 768px)  {
  .get-in-touch-container > h1 > a {
    font-size: 60px;
    line-height: 120px;
    font-weight: 800;
  }

  .get-in-touch-container > h1 > a {
    font-size: 100px;
    line-height: 120px;
    font-weight: 800;
    color: #FFF;
    text-shadow: 2px 0 #000, -2px 0 #000, 0 2px #000, 0 -2px #000,
    1px 1px #000, -1px -1px #000, 1px -1px #000, -1px 1px #000;
    transition: 0.2s all ease-in-out;
  }
}

@media only screen and (min-width: 570px) and (max-width: 991px){
  .get-in-touch-container {
    display: flex;
    flex:1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 160px;
    margin-bottom: 160px;
  }

  .get-in-touch-container > p {
    margin-bottom: -5px;
    font-weight: 500;
  }
  
  .get-in-touch-container > h1 > a:hover {
    color: #FFD600;
  }
}

@media only screen and (min-width: 991px) {
  .get-in-touch-container {
    display: flex;
    flex:1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 200px !important;
    margin-bottom: 200px !important;
  }
  
  .get-in-touch-container > h1 > a {
    font-size: 120px;
    line-height: 120px;
    font-weight: 800;
    color: #FFF;
    text-shadow: 2px 0 #000, -2px 0 #000, 0 2px #000, 0 -2px #000,
    1px 1px #000, -1px -1px #000, 1px -1px #000, -1px 1px #000;
    transition: 0.2s all ease-in-out;
  }
  
  .get-in-touch-container > p {
    margin-bottom: -5px;
    font-weight: 500;
  }
  
  .get-in-touch-container > h1 > a:hover {
    color: #FFD600;
  }
  
}