.fact-item-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.fact-item-icon, .fact-item-image {
  margin-right: 20px;
}

.fact-item-icon, .fact-item-text {
  color: #FFF !important;
  font-weight: "400";
}

.fact-item-container:not(:last-child) {
  margin-bottom: 50px;
}

.fact-item-text {
  font-size: 30px;
  line-height: 40px;
}


@media only screen and (max-width:576px) {
  .fact-item-image {
    width: 20px;
    margin-bottom: 15px;
  }

  .fact-item-icon {
    
  }
}

@media only screen and (min-width:576px) and (max-width:990px) {
  .fact-item-image {
    width: 30px;
    margin-bottom: 15px;
  }
}

@media only screen and (min-width:993px) {
  .fact-item-text {
    font-size: 30px !important;
    line-height: 40px !important;
  }

  .fact-item-icon, .fact-item-image {
    margin-right: 30px;
  }
}