.social-info-container {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
}

.social-info-container:first-child {
  margin-bottom: 10px;
}

.social-info-container > p > a {
  color: #FFF;
}
.social-info-container > p {
  margin-bottom: 0;
  color: #FFF;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  margin-left: 5px;
}