.input-field-container > p {
  font-size: 14px;
  line-height: 15px;
  font-weight: 600;
  margin-bottom: 10px;
}

.input-field-container > input, textarea{
  border: 1px solid #C2C2C2;
  padding: 17px;
  border-radius: 5px;
  display: flex;
  flex: 1;
  flex-direction: row;
  width: 100%;
  margin-bottom: 30px;
}

.input-field-container > input:focus, textarea:focus {
  outline: 1px solid #a3a3a3 !important;
}