.project-text-container {
  padding: 80px 100px;
}

.project-text-text-container {
  margin-top: 30px;
  padding-bottom: 40px;

}

.project-text-text-item:not(:first-child) {
  margin-top: 20px
}

.project-text-container-padding {
  padding-top: 160px;
  padding-bottom: 160px;
}

@media only screen and (max-width: 576px) {
  .Digital-wireframes {
    padding-top: 40px !important;
    padding-bottom: 0px !important;
  }

  .Mockups {
    padding-top: 80px !important;
    padding-bottom: 40px !important;
  }
}

@media only screen and (min-width: 991px) {
  .project-text-container {
    padding: 100px 100px;
  }
  
  .project-text-text-container {
    margin-top: 70px;
    padding-bottom: 100px;
  
  }
  
  .project-text-text-item:not(:first-child) {
    margin-top: 40px
  }
  
  .project-text-container-padding {
    padding-top: 200px;
    padding-bottom: 200px;
  }
  
}