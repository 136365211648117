.project-title-image-container {
  padding: 80px 0;
}
.project-title-image-title {
  text-align: center;
}

.project-title-image-image {
  width: 100%;
  margin-top: 20px;
}

@media only screen and (max-width: 576px) {
  .project-title-image-container {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

@media only screen and (min-width: 991px) {
  .project-title-image-container {
    margin-top: 100px;
    padding: 100px 0;
  }
  .project-title-image-title {
    text-align: center;
  }
  
  .project-title-image-image {
    width: 100%;
    margin-top: 100px;
  
  }
  
}