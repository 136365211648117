.footer-container {
  background-color: #0500FF;
  display: flex;
  flex:1;
  flex-direction: column;
  align-items: center;
  padding: 10px 0;
}

.footer-container > p {
  font-size: 10px;
  color: #FFF;
  text-align: center;
  padding: 0;
  margin: 0;
}

.footer-container > p > a {
  color: #FFF;
}

@media only screen and (min-width: 991px) {
  .footer-container {
    background-color: #0500FF;
    display: flex;
    flex:1;
    flex-direction: column;
    align-items: center;
    padding: 50px 0;
  }
  
  .footer-container > p {
    font-size: 18px;
    color: #FFF;
    text-align: center;
    padding: 0;
    margin: 0;
  }
  
  .footer-container > p > a {
    color: #FFF;
  }
  
}