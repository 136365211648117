

@media only screen and (max-width: 500px) {
  .project-card-container{
    display: flex;
    flex-direction: column !important;
    flex: 1;
    background-color: #F4F4F4;
    max-height: 1095px !important;
  }
  
  .project-card-container:not(:last-child) {
    margin-bottom: 100px !important;
  }
  
  .project-card-image-container {
    display: flex;
    flex: 0.5;
    justify-content: center;
    width: 100% !important ;
    aspect-ratio: 1/1;
    /* height: 500px !important; */
    overflow-y: hidden;
  }
  
  .project-card-image-container > img {
    margin-top: 70px;
    width: 100% !important;
    object-fit: contain;
  }
  
  .project-card-text-container {
    background-color: blue;
    display: flex;
    flex: 0.5;
    flex-direction: column;
    justify-content: center;
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
  
  .project-card-text-container > * {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
  
  .project-card-text-container > h2 {
    color: #FFF;
    font-weight: 800;
    font-size: 35px;
    line-height: 35px;
    /* line-height: 45px; MISLIM DA JE OVO BOLJE */ 
    margin-bottom: 35px;
  }
  
  .project-card-text-container > p {
    color: #FFF;
    font-weight: 500;
    font-size: 15px;
    line-height: 30px;
    margin-bottom: 35px;
    white-space: pre-wrap;
  }
  
}


@media only screen and (max-width: 991px) and (min-width: 500px) {
  .project-card-container{
    display: flex;
    flex-direction: column !important;
    flex: 1;
    background-color: #F4F4F4;
    max-height: 895px !important;
  }
  
  .project-card-container:not(:last-child) {
    margin-bottom: 100px !important;

  }
  
  .project-card-image-container {
    display: flex;
    flex: 0.5;
    justify-content: center;
    width: 100% !important ;
    /* height: 500px !important; */
    overflow-y: hidden;
  }
  
  .project-card-image-container > img {
    margin-top: 70px;
    width: 100% !important;
    object-fit: contain;
  }
  
  .project-card-text-container {
    background-color: blue;
    display: flex;
    flex: 0.5;
    flex-direction: column;
    justify-content: center;
    /* padding-left: 80px; */
    /* padding-right: 80px; */

    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }
  
  .project-card-text-container > * {
    padding-left: 80px;
    padding-right: 80px;
  }
  
  .project-card-text-container > h2 {
    color: #FFF;
    font-weight: 800;
    font-size: 35px;
    line-height: 35px;
    /* line-height: 45px; MISLIM DA JE OVO BOLJE */ 
    margin-bottom: 35px;
  }
  
  .project-card-text-container > p {
    color: #FFF;
    font-weight: 500;
    font-size: 15px;
    line-height: 30px;
    margin-bottom: 35px;
  
  }
  
}

@media only screen and (min-width: 991px) {
  .project-card-container{
    display: flex;
    flex-direction: row;
    flex: 1;
    background-color: #F4F4F4;
    height: 595px;
  }
  
  .project-card-container:not(:last-child) {
    margin-bottom: 200px;
  }
  
  .project-card-image-container {
    display: flex;
    flex: 0.5;
    justify-content: center;
  }
  
  .project-card-image-container > img {
    margin-top: 70px;
  }
  
  .project-card-text-container {
    background-color: blue;
    display: flex;
    flex: 0.5;
    flex-direction: column;
    justify-content: center;
    /* padding-left: 80px; */
    /* padding-right: 80px; */
  }
  
  .project-card-text-container > * {
    padding-left: 80px;
    padding-right: 80px;
  }
  
  .project-card-text-container > h2 {
    color: #FFF;
    font-weight: 800;
    font-size: 35px;
    line-height: 35px;
    /* line-height: 45px; MISLIM DA JE OVO BOLJE */ 
    margin-bottom: 35px;
  }
  
  .project-card-text-container > p {
    color: #FFF;
    font-weight: 500;
    font-size: 15px;
    line-height: 30px;
    margin-bottom: 35px;
  
  }
}