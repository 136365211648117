/* .user-research-element-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-left: 30px;
  margin-right: 30px;
  flex: 1;
  margin-bottom: 20px;
}


.user-research-element-title > h4 {
  font-weight: 700;
  font-size: 16px;
  text-align: center;
}

.user-research-element-title > h4:first-child {
  margin-top: 20px;
  margin-bottom: -5px;
}

.user-research-element-container > p {
  color: #fff;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 40px;
  font-size: 14px;
}

@media only screen and (max-width: 991px) and (min-width: 576px) {
  .user-research-element-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 20px;
    flex: 1;
  }
  
  
  .user-research-element-title > h4 {
    font-weight: 700;
    font-size: 24px;
    text-align: center;
  }
  
  .user-research-element-title > h4:first-child {
    margin-top: 40px;
    margin-bottom: 2px;
  }
  
  .user-research-element-container > p {
    color: #fff;
    text-align: center;
    margin-top: 20px;
    font-size: 16px;
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 991px) {
  .user-research-element-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-left: 30px;
    margin-right: 30px;
    flex: 1;
  }
  
  
  .user-research-element-title > h4 {
    font-weight: 700;
    font-size: 30px;
    text-align: center;
  }
  
  .user-research-element-title > h4:first-child {
    margin-top: 40px;
    margin-bottom: -5px;
  }
  
  .user-research-element-container > p {
    color: #fff;
    text-align: center;
    margin-top: 40px;
    margin-bottom: 40px;
    font-size: 20px;
  }
} */

.user-research-element-container {
  display: flex;
  flex-direction: row;
  align-items: start;
}

.user-research-element-title {
  display: flex;
  flex-direction: column;
  align-items: start;
  text-align: center;
  flex: 1;
}

.user-research-element-title > h4 {
  font-weight: 700;
  font-size: 16px;
  text-align: center;
  display: flex;
  flex-direction: row;
  flex:1;
  color: rgba(255, 214, 0, 1);

}

.user-research-element-title > p {
  color: rgba(0, 0, 0, 1);
  text-align: center;
  font-size: 14px;
}

@media only screen and (max-width: 576px) {
  .user-research-element-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
  }
  
  .user-research-element-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    flex: 1;
    margin: 20px 0;
  }
  
  .user-research-element-title > h4 {
    font-weight: 700;
    font-size: 16px;
    text-align: center;
    display: flex;
    flex-direction: row;
    flex:1;
    color: rgba(255, 214, 0, 1);
    margin-bottom: 20px;
  
  }
  
  .user-research-element-title > p {
    color: rgba(0, 0, 0, 1);
    text-align: center;
    font-size: 14px;
  }
}



@media only screen and (max-width: 991px) and (min-width: 576px) {
  .user-research-element-title {
    flex: 1;
  }

  .user-research-element-title > h4 {
    font-weight: 700;
    font-size: 24px;
    color: rgba(255, 214, 0, 1);
    text-align: left;
  }
  
  .user-research-element-title > h4:first-child {
    margin-bottom: 2px;
    color: rgba(255, 214, 0, 1);
  }
  
  .user-research-element-title > p {
    color: rgba(0, 0, 0, 1);
    text-align: left;
    margin-top: 20px;
    font-size: 16px;
    margin-bottom: 20px;
  }

  .user-research-element-container > .number-circle {
    margin-right: 20px;
  }

  .user-research-element-container {
    margin: 50px 0 ;
  }
}

@media only screen and (min-width: 991px) {
  .user-research-element-container {
    margin: 80px 0;
  }
  
  .user-research-element-title > h4 {
    color: rgba(255, 214, 0, 1);
    font-weight: 700;
    font-size: 30px;
    text-align: left;
    line-height: 40px;
  }
  
  .user-research-element-container > .number-circle {
    margin-right: 30px;
  }
  
  .user-research-element-title > h4:first-child {
    color: rgba(255, 214, 0, 1);
    margin-bottom: 0px;
  }
  
  .user-research-element-title  {
    flex: 1;
  }

  .user-research-element-title > p {
    color: rgba(0, 0, 0, 1);
    text-align: left;
    margin-top: 20px;
    margin-bottom: 0;
    font-size: 20px;
    line-height: 40px;
  }
} 