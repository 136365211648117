@media only screen and (max-width: 576px) {
  .quote-cards-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  
  .quote-card-container > :first-child {
    margin-bottom: 40px;
  }
  
  
  .quote-card-left {
    position: relative;
    z-index: 9;
    width: 90%;
  }
  
  
  .quote-card-center {
    position: relative;
    z-index: 99;
    background-color:#FFD600;
    top: -80px
  }
  
  .quote-card-center > p {
    color: #000000;
  }

  .quote-card-left {
    margin-bottom: 4dvh !important;
  }
  
  .quote-card-right {
    position: relative;
    z-index: 999;
    background-color:#000000;
    top: -140px;
    width: 90%;
  }
  
  .quote-card-right > p:last-child {
    margin-bottom: 80px;
  }
}

@media only screen and (max-width: 991px) and (min-width: 576px) {
  .quote-cards-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }


  .quote-card-container > :first-child {
    margin-bottom: 40px;
  }

  
  .quote-card-left {
    position: relative;
    z-index: 9;
    width: 90%;
  }
  
  
  .quote-card-center {
    position: relative;
    z-index: 99;
    background-color:#FFD600;
    top: -80px
  }
  
  .quote-card-center > p {
    color: #000000;
  }
  
  .quote-card-right {
    position: relative;
    z-index: 999;
    background-color:#000000;
    top: -140px;
    width: 80%;
  }

  .quote-card-right > p:last-child {
    margin-bottom: 80px;
  }
}

@media only screen and (min-width: 991px) {
  .quote-cards-container {
    display: flex;
    flex: 1;
    flex-direction: row;
    position: relative;
    left: 20px;
    margin-top: 200px;
    justify-content: center;
  }
  
  
  .quote-card-left {
    position: relative;
    z-index: 99;
    left: 20px;
    
  }
  
  .quote-card-center {
    position: relative;
    z-index: 99;
    top: -40px;
    left: -20px;
    background-color:#FFD600;
  }
  
  .quote-card-center > p {
    color: #000000;
  }
  
  .quote-card-right {
    position: relative;
    z-index: 9;
    top: 20px;
    left: -40px;
    background-color:#000000;
  }
}