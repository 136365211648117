.project-hero-container {
  display: flex;
  flex-direction: row;
  flex: 1;
  margin-top: 30px;
}

.project-text-container {
  padding-left: 40px !important;
  padding-right: 40px !important;
}

.project-main-title {
  display: flex;
  flex: 1;
  text-align: center;
  justify-content: center;
  margin-bottom: 70px;
}

.project-text {
  text-align: center;
}

@media only screen and (min-width: 991px) {
  .project-hero-container {
    display: flex;
    flex-direction: row;
    flex: 1;
    margin-top: 107px;
  }
  
  .project-main-title {
    display: flex;
    flex: 1;
    text-align: center;
    justify-content: center;
    margin-bottom: 70px;
  }
  
  .project-text {
    margin: 0 60px;
    text-align: center;
  }
}