/* .user-research-container {
  background-color: #0500FF;
  padding: 100px 0 !important;
  display: flex;
  flex:1;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.user-research-container > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


.user-research-elements-container {
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-top: 70px;
}

.user-research-title {
  color: #FFF;
}

@media only screen and (min-width: 991px) {
  .user-research-container {
    background-color: #0500FF;
    padding: 100px 0 !important;
    display: flex;
    flex:1;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  
  .user-research-container > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  
  .user-research-elements-container {
    display: flex;
    flex-direction: row;
    align-items: start;
    margin-top: 70px;
  }
  
  .user-research-title {
    color: #FFF;
  }
} */


.user-research-container > h1 {
  color: black;
}


.user-research-elements-container {
 margin-top: 60px;
}

.user-research-title {
  color: #000;
}

.user-research-container > div {
  padding: 0 40px;
}

@media only screen and (max-width: 576px) {
  .user-research-title {
    text-align: center;
  }
}

@media only screen and (min-width: 991px) {
  .user-research-container {
   
  }

  .user-research-container > div {
    padding: 0 40px;
  }
  
  
  .user-research-elements-container {
  
  }
  
  
}
