@media only screen and (max-width: 576px) {
  .quote-card-container {
    width: 100%;
    height: 510px;
    max-width: 100%;
    max-height: 810px;
    background-color: #0500FF;
    padding-left: 76px;
    padding-right: 76px;
    padding-top: 64px;
    padding-bottom: 12px;
    display: flex;
    flex:1;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  
  .quote-card-container > p {
    color: #FFFFFF;
    font-weight: 600 !important;
  }
  
  .quote-card-container > p:last-child {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    font-weight: 700 !important;
    margin-bottom: 100px;
  
  }
}
@media only screen and (max-width: 991px) and (min-width: 576px) {
  .quote-card-container {
    width: 100%;
    height: 510px;
    max-width: 100%;
    max-height: 510px;
    background-color: #0500FF;
    padding-left: 76px;
    padding-right: 76px;
    padding-top: 64px;
    padding-bottom: 12px;
    display: flex;
    flex:1;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  
 

  .quote-card-container > p {
    color: #FFFFFF;
    font-weight: 600 !important;
  }
  
  .quote-card-container > p:last-child {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    font-weight: 700 !important;
    margin-bottom: 100px;
  
  }
}

@media only screen and (min-width: 991px) {
  .quote-card-container {
    width: 380px;
    height: 480px;
    max-width: 380px;
    max-height: 480px;
    background-color: #0500FF;
    padding-left: 76px;
    padding-right: 76px;
    padding-top: 64px;
    padding-bottom: 12px;
    display: flex;
    flex:1;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  
  .quote-card-container > p {
    color: #FFFFFF;
    font-weight: 600 !important;
  }
  
  .quote-card-container > p:last-child {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    font-weight: 700 !important;
  
  }
}