.impact-container {
  background-color: #0500FF;
  padding: 80px 40px;
}

.impact-content-container > h4 {
  color: #FFF;
}

.impact-text-container {
  margin-top: 30px; 
}


.impact-text-container > p {
  color: #FFF;
}

.impact-text-container > p:not(:last-child) {
  margin-bottom: 20px;
}

@media only screen and (max-width: 576px) {
  .impact-container {
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media only screen and (min-width: 991px) {
  .impact-container {
    background-color: #0500FF;
    padding: 100px 0;
  }
  
  .impact-content-container > h4 {
    color: #FFF;
  }
  
  .impact-text-container {
    margin-top: 70px; 
  }
  
  
  .impact-text-container > p {
    color: #FFF;
  }
  
  .impact-text-container > p:not(:last-child) {
    margin-bottom: 70px;
  }
}