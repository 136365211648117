.project-image-container > img {
  width: 100%;
}

.project-image-padding {
  padding: 40px 0;
}

@media only screen and (max-width: 576px) {
  .project-image-padding {
    padding-bottom: 0;
  }
}
@media only screen and (min-width: 576px) {
  .project-image-container > img {
    width: 100%;
  }
  
  .project-image-padding {
    padding: 80px 0;
  }
  
}
@media only screen and (min-width: 991px) {
  .project-image-container > img {
    width: 100%;
  }
  
  .project-image-padding {
    padding: 100px 0;
  }
}